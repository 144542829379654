/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// import SearchBar from 'components/SearchBar';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editTradingPairsRequest } from 'redux/reducers/tradingPairs/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ITradingPairsItemProps } from './types';

// ==========================================:
export const TradingPairsItem: FC<ITradingPairsItemProps> = ({ data }) => {
	const [switcher, setSwitcher] = useState(Boolean(data.active));
	const [decimal, setDecimal] = useState(String(data.view_decimal));
	const [isRedact, setIsRedact] = useState(false);
	const [notCorrect, setNotCorrect] = useState(false);
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_ASSET_PAIRS);

	const dispatch = useDispatch();

	const handleTradingPairsChange = () => {
		if (notCorrect || !canEdit) return;
		dispatch(
			editTradingPairsRequest({
				code: data.code,
				view_decimal: Number(decimal),
				active: String(Number(switcher)),
			}),
		);
	};
	const handleSwitcherChange = (sw: boolean) => {
		setSwitcher(sw);
		dispatch(
			editTradingPairsRequest({
				code: data.code,
				active: String(Number(sw)),
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency Pair</p>
				<p>{data.code.toUpperCase().replace('_', '/')}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">User Orders</p>
				<p>{data.orders_count.users_orders_count}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total Number of Orders</p>
				<p>{data.orders_count.total_orders_count}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={switcher}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked);
							}}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Decimal</p>
				{!isRedact ? (
					<p>{decimal}</p>
				) : (
					<div className="table-input">
						<input
							type="text"
							value={decimal}
							onChange={(e) => {
								setDecimal(e.target.value.replace(/[^\d]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{isRedact ? (
						<>
							<button
								type="submit"
								onClick={() => {
									setDecimal(String(data.view_decimal));
									setIsRedact(!isRedact);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>

							<button
								type="button"
								onClick={handleTradingPairsChange}
								className={notCorrect ? 'button-not-active' : ''}
							>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
										stroke="#00DA83"
										strokeLinecap="square"
									/>
								</svg>
							</button>
						</>
					) : (
						<button disabled={!canEdit} type="button" onClick={() => setIsRedact(!isRedact)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
