/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLocaleTimeFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleTimeString();
export const getLocaleDateFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleDateString();
export const getTimeParserDateFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleDateString();
};
export const getTimeParserTimeFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleTimeString();
};

export const getTimeSum = (timeStart: any): string => {
	const one: any = new Date(timeStart);
	const two: any = new Date();
	const milliseconds = two - one;
	const seconds = milliseconds / 1000;
	const minutes = seconds / 60;
	const hours = minutes / 60;
	const days = hours / 24;
	return String(Math.floor(days));
};

export const formatDateString = (input: string): string => {
	const date = new Date(input);

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
