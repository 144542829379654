/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import FormConrol from 'ui/Formik/FormConrol';
import { IInput } from './types';

// ==========================================:
const Input: FC<IInput> = (props) => {
	const { ariaLabel, type, field, placeholder, inputMode } = props;

	return (
		<FormConrol ariaLabel={ariaLabel} {...props}>
			<input
				className="input-item input-item--left-icon input-item--underline"
				{...field}
				type={type}
				placeholder={placeholder}
				inputMode={inputMode || null}
			/>
		</FormConrol>
	);
};

export default Input;
