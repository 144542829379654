import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Trades from 'components/Trades/index';

// ================================================:
const TradesPage: FC = () => {
	return (
		<Dashboard title="Trades">
			<Trades />
		</Dashboard>
	);
};
export default TradesPage;
