import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { IGetTranslateParams, IUpdateTranslatePayload, ITranslates } from './types';
import {
	getTranslatesRequest,
	getTranslatesSuccess,
	updateTranslatesRequest,
	updateTranslatesSuccess,
	getTranslatesError,
	updateTranslatesError,
} from './reducer';

// ===================================================:
function* getTranslatesWorker(action: PayloadAction<IGetTranslateParams>) {
	const { payload } = action;

	try {
		const response: ITranslates[] = yield call(api.translates.getTranslates, payload);
		yield put(getTranslatesSuccess(response));
	} catch (error) {
		yield put(getTranslatesError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// ===================================================:
function* updateTranslationsWorker({ payload }: PayloadAction<IUpdateTranslatePayload>) {
	const { apiParams, onSuccess } = payload;

	try {
		const response: ITranslates = yield call(api.translates.updateTranslates, apiParams);
		yield put(
			updateTranslatesSuccess({
				path: apiParams.path,
				response,
			}),
		);
		onSuccess?.();
	} catch (error) {
		yield put(updateTranslatesError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* translationsSaga() {
	yield takeEvery(getTranslatesRequest.type, getTranslatesWorker);
	yield takeEvery(updateTranslatesRequest.type, updateTranslationsWorker);
}
