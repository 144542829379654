/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getWhitelistIpIsLoad, getWhitelistIpList } from 'redux/reducers/userManagement/selectors';
import queryString from 'query-string';
import { getWhitelistIpRequest } from 'redux/reducers/userManagement/reducer';
import Pagination from 'ui/Pagination';
import { IWhitelistIpListRequestPayload } from 'redux/reducers/userManagement/types';
import {
	whitelistingIPStatus,
	WHITELISTING_IP_STATUS_ALL,
	WHITELISTING_IP_STATUS_ALL_TEXT,
	WHITELISTING_IP_STATUS_NOT_VERIFIED,
	WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT,
	WHITELISTING_IP_STATUS_WHITELISTING,
	WHITELISTING_IP_STATUS_WHITELISTING_TEXT,
} from 'redux/reducers/userManagement/constants';
import Loader from 'ui/Loader';
import { WhitelistingIPItem } from './WhitelistingIPItem';

export const WhitelistingIP: FC = () => {
	const { id } = useParams<{ id: string }>();

	const whitelistIp = useSelector(getWhitelistIpList);
	const whitelistIpLoader = useSelector(getWhitelistIpIsLoad);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [statusFilter, setStatusFilter] = useState(WHITELISTING_IP_STATUS_ALL);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);

		const searchParams =
			statusFilter !== WHITELISTING_IP_STATUS_ALL
				? `?page=${String(pageNumber)}&text=${statusFilter}&field=verified`
				: `?page=${String(pageNumber)}`;

		const params: IWhitelistIpListRequestPayload =
			statusFilter !== WHITELISTING_IP_STATUS_ALL
				? {
						id,
						current_page: pageNumber || 1,
						search_field: 'verified',
						search_value: statusFilter,
				  }
				: {
						id,
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getWhitelistIpRequest(params));
	};
	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: ``,
		});
		setCurrentPage(1);

		dispatch(getWhitelistIpRequest({ id }));
	}, [dispatch, id]);

	const [openWhitelisting, setOpenWhitelisting] = useState(false);

	const filterHandler = (filter: number) => {
		setStatusFilter(filter);
		setOpenWhitelisting(false);

		const params = {
			id,
			current_page: 1,
			search_value: filter,
			search_field: 'verified',
		};

		if (filter !== WHITELISTING_IP_STATUS_ALL) {
			dispatch(getWhitelistIpRequest(params));
		} else {
			dispatch(getWhitelistIpRequest({ id, current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search:
				filter !== WHITELISTING_IP_STATUS_ALL
					? `?page=1&text=${String(filter)}&field=verified`
					: `?page=1`,
		});
		setCurrentPage(1);
	};

	return (
		<>
			<div className="content-block content-block--user-management">
				{!whitelistIp && !whitelistIpLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">No user found...</p>
					</div>
				)}
				{whitelistIpLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="content-block__inside">
							<div className="user-transaction">
								<div className="user-transaction__header">
									<div className="user-info user-info--ip">
										<div className="user-info__block personal-info">
											<span className="personal-info__name">User ID</span>
											<div className="personal-info__desc">
												<span className="personal-info__text">{id}</span>
											</div>
										</div>
										<div className="user-info__block personal-info">
											<span className="personal-info__name">Email</span>
											<div className="personal-info__desc">
												<span className="personal-info__text">{whitelistIp?.email}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="content-block__inside content-block__inside--bt content-block__inside--ip">
							<div className="table-block table-block--ip">
								<div className="table-wrapper">
									<div className="table table--ip">
										<div className="table-header">
											<div className="tr">
												<div className="td">
													<div className="td-name">
														<p>IP</p>
													</div>
												</div>
												<div className="td td--right td--status">
													<div className="td-name td-name--status">
														<p
															className="td-name--status"
															onClick={() => setOpenWhitelisting(true)}
														>
															{whitelistingIPStatus?.[statusFilter]?.statusText}
															<span className="arrow-icon">
																<svg
																	width="8"
																	height="5"
																	viewBox="0 0 8 5"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path d="M1 1L4 4L7 1" stroke="#011E26" strokeLinecap="square" />
																</svg>
															</span>
														</p>
														<div className={`status-drop ${openWhitelisting ? 'active' : ''}`}>
															<ul className="status-drop__list">
																<li
																	className="status-drop__item"
																	onClick={() => filterHandler(WHITELISTING_IP_STATUS_ALL)}
																>
																	{WHITELISTING_IP_STATUS_ALL_TEXT}
																</li>
																<li
																	className="status-drop__item"
																	onClick={() => filterHandler(WHITELISTING_IP_STATUS_WHITELISTING)}
																>
																	{WHITELISTING_IP_STATUS_WHITELISTING_TEXT}
																</li>
																<li
																	className="status-drop__item"
																	onClick={() => filterHandler(WHITELISTING_IP_STATUS_NOT_VERIFIED)}
																>
																	{WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT}
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="td">
													<div className="td-name td-name--action">
														<p>Action</p>
													</div>
												</div>
											</div>
										</div>
										{whitelistIp?.data?.length && whitelistIp?.data?.length > 0 ? (
											whitelistIp?.data?.map((item) => <WhitelistingIPItem data={item} />)
										) : (
											<p style={{ padding: '20px' }}>IP & Whitelist is empty</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			{(whitelistIp?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={whitelistIp?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
			{/* {openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)} */}
		</>
	);
};
