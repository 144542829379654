import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { changeUserPassRequest, check2faEnablesRequest } from 'redux/reducers/settings/reducer';
// import { getCheck2faEnablesLoader } from 'redux/reducers/settings/selectors';
import Dashboard from 'layouts/Dashboard';
import ChangePassForm from 'components/Forms/ChangePassForm';
import Google2faActions from 'components/Settings2fa/Google2faActions';
import { IChangePassFormValue } from 'components/Forms/ChangePassForm/types';
import 'react-tabs/style/react-tabs.css';
import Loader from 'ui/Loader';

// ================================================:
const Settings: FC = () => {
	const dispatch = useDispatch();
	// const check2faEnablesLoader = useSelector(getCheck2faEnablesLoader);
	const check2faEnablesLoader = false;

	const handleChangePassFormSubmit = (value: IChangePassFormValue) => {
		dispatch(changeUserPassRequest(value));
	};

	useEffect(() => {
		dispatch(check2faEnablesRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Settings">
			<>
				<div className="title-block title-block--admin-managment">
					<p className="title">Settings</p>
				</div>

				<Tabs className="settings-tabs">
					<TabList className="user-settings user-settings--settings">
						<Tab className="user-settings__item">
							<span className="user-settings__link">Change Password</span>
						</Tab>
						<Tab className="user-settings__item">
							<span className="user-settings__link">Two-Factor Authentication</span>
						</Tab>
					</TabList>
					<TabPanel>
						<div className="forms-block">
							<div className="form form--settings">
								<ChangePassForm changePassFormSubmit={handleChangePassFormSubmit} />
							</div>
						</div>
					</TabPanel>
					<TabPanel>
						{check2faEnablesLoader ? (
							<div className="check-2fa-enable-wrapper">
								<Loader />
							</div>
						) : (
							<Google2faActions />
						)}
					</TabPanel>
				</Tabs>
			</>
		</Dashboard>
	);
};

export default Settings;
