import { FC } from 'react';
import Popup from 'reactjs-popup';
import { getAccountUserData } from 'redux/reducers/transactions/selectors';
import { useSelector } from 'react-redux';
import { IProps } from './types';

// ================================================:
export const Success: FC<IProps> = ({ openModal, closeModal }) => {
	const assets = useSelector(getAccountUserData);

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--medium">
						<div className="popup-header">
							<p className="popup-header__title">Transaction created succesfully</p>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="withdrawal-options ">
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">User ID</span>
									<span className="withdrawal-option__desc">{assets?.user_id}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">User name</span>
									<span className="withdrawal-option__desc">{assets?.user?.username}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">Amount</span>
									<span className="withdrawal-option__desc">{assets?.amount}</span>
								</div>
								<div className="withdrawal-option">
									<span className="withdrawal-option__title">Reference code</span>
									<span className="withdrawal-option__desc">{assets?.user?.payment_reference}</span>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--sb">
							<button onClick={closeModal} type="button" className="button button--full-width">
								Confrim
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
