import { FC } from 'react';
import { whitelistingIPStatus } from 'redux/reducers/userManagement/constants';
import { IWhitelistingIPItemProps } from './types';
import { WhitelistingIPActions } from './WhitelistingIPActions';

export const WhitelistingIPItem: FC<IWhitelistingIPItemProps> = ({ data }) => {
	const status = data?.verified ?? 0;

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Ip</p>
					<p>{data.ip}</p>
				</div>
				<div className="td td--status">
					<p className="td-hidden-name">In Whitelisting</p>
					<div>
						<span className={`status ${whitelistingIPStatus[data.verified].statusClass}`}>
							{whitelistingIPStatus[data.verified].statusText}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Action</p>
					<WhitelistingIPActions status={status} id={data.id} userId={data.user_id} />
				</div>
			</div>
		</div>
	);
};
