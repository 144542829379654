import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeGeneralRequest } from 'redux/reducers/сurrencies/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { IDisputeProps, ItemKey } from './types';

const CurrenciesGeneralTableItem: FC<IDisputeProps> = ({ item, noNetworks }) => {
	const dispatch = useDispatch();
	const {
		code,
		depositable: depositTable,
		withdrawable: withdrawAble,
		exchangeable: exchangeAble,
		id,
	} = item;
	const { checkEditable } = usePermission();

	const canEdit = checkEditable(permissions.MANAGE_CURRENCIES);

	const formInit = {
		depositable: depositTable,
		withdrawable: withdrawAble,
		exchangeable: exchangeAble,
		network_id: code || '',
		id: id || 0,
	};
	const [form, setForm] = useState(formInit);

	const handleSwitcherChange = (sw: boolean, text: ItemKey) => {
		if (!canEdit) return;

		const newValue = sw ? 1 : 0;

		setForm({ ...form, [text]: newValue });

		const body = {
			id: item.id,
			[text]: newValue,
		};
		dispatch(
			changeGeneralRequest({
				apiParams: { ...body },
				onError: () => {
					setForm((prev) => ({
						...prev,
						[text]: item[text],
					}));
				},
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Deposit</p>
				{!noNetworks && (
					<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
						<label className="switch__label">
							<input
								type="checkbox"
								className="hidden"
								checked={!!form.depositable}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleSwitcherChange(e.target.checked, 'depositable');
								}}
								disabled={!canEdit}
							/>
							<div className="switch__toggler" />
						</label>
					</div>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Withdrawal</p>
				{!noNetworks && (
					<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
						<label className="switch__label">
							<input
								type="checkbox"
								className="hidden"
								checked={!!form.withdrawable}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleSwitcherChange(e.target.checked, 'withdrawable');
								}}
								disabled={!canEdit}
							/>
							<div className="switch__toggler" />
						</label>
					</div>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Convert</p>
				<div className={`switch switch--type2 ${!canEdit ? 'switch--disabled' : ''}`}>
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!form.exchangeable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'exchangeable');
							}}
							disabled={!canEdit}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
		</div>
	);
};

export default CurrenciesGeneralTableItem;
