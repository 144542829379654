import { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getAddAssetsPairLoading,
	getEditTradingPairsLoading,
	getTradingPairs,
} from 'redux/reducers/tradingPairs/selectors';
import { getTradingPairsRequest } from 'redux/reducers/tradingPairs/reducer';
import Loader from 'ui/Loader';
import { TradingPairsItem } from './TradingPairsItem';

export const SpotPairs: FC = () => {
	const dispatch = useDispatch();
	const tradingPairsList = useSelector(getTradingPairs);
	const editLoading = useSelector(getEditTradingPairsLoading);
	const addAssetsPairLoading = useSelector(getAddAssetsPairLoading);

	const pairs = [...(tradingPairsList?.tradingPairs?.pairs ?? [])].reverse();

	useLayoutEffect(() => {
		if (!editLoading && !addAssetsPairLoading) {
			dispatch(getTradingPairsRequest());
		}
	}, [editLoading, addAssetsPairLoading, dispatch]);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--withdrawals-2">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Currency Pair</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>User Orders</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>Total Number of Orders</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>Status</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>Decimal</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>

					<div className="table-body">
						{!tradingPairsList?.tradingPairs?.pairs?.length &&
							!tradingPairsList?.tradingPairsLoader && (
								<div className="user-management-empty-data">
									<p className="user-management-empty__text">No trading pairs found...</p>
								</div>
							)}

						{tradingPairsList?.tradingPairsLoader ? (
							<div className="list-loader">
								<Loader />
							</div>
						) : (
							<div>
								{pairs?.map((item) => (
									<TradingPairsItem data={item} key={item.code} />
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
