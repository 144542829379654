/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	WHITELISTING_IP_STATUS_NOT_VERIFIED,
	WHITELISTING_IP_STATUS_WHITELISTING,
} from 'redux/reducers/userManagement/constants';
import {
	removeWhitelistIpRequest,
	verifyWhitelistIpRequest,
} from 'redux/reducers/userManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { RemoveWhitelistingIPModal } from './RemoveWhitelistingIPModal';
import { IWhitelistingIPActionsProps } from './types';
import { VerifyWhitelistingIPModal } from './VerifyWhitelistingIPModal';

export const WhitelistingIPActions: FC<IWhitelistingIPActionsProps> = ({ status, id, userId }) => {
	const dispatch = useDispatch();
	const [openRemoveModal, setOpenRemoveModal] = useState(false);
	const closeRemoveModal = () => setOpenRemoveModal(false);
	const [openVerifyModal, setOpenVerifyModal] = useState(false);
	const closeVerifyModal = () => setOpenVerifyModal(false);

	const { checkEditable } = usePermission();

	const removeWhitelistIpRequestHandler = () => {
		if (!checkEditable(permissions.MANAGE_USERS)) return;
		dispatch(removeWhitelistIpRequest({ id, userId }));
	};
	const verifyWhitelistIpRequestHandler = () => {
		if (!checkEditable(permissions.MANAGE_USERS)) return;
		dispatch(verifyWhitelistIpRequest({ id, userId }));
	};

	return (
		<>
			<div className="table-buttons table-buttons--ip">
				{status === WHITELISTING_IP_STATUS_NOT_VERIFIED ? (
					<button
						type="button"
						className="table-buttons__success-btn"
						disabled={!checkEditable(permissions.MANAGE_USERS)}
						onClick={() => setOpenVerifyModal(true)}
					>
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
								stroke="#00DA83"
								strokeLinecap="square"
							/>
						</svg>
					</button>
				) : status === WHITELISTING_IP_STATUS_WHITELISTING ? (
					<button
						type="button"
						className="table-buttons__delete-btn"
						disabled={!checkEditable(permissions.MANAGE_USERS)}
						onClick={() => setOpenRemoveModal(true)}
					>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2.25 4.5H3.75H15.75"
								stroke="#F44336"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
								stroke="#F44336"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				) : null}
			</div>
			<RemoveWhitelistingIPModal
				openModal={openRemoveModal}
				closeModal={closeRemoveModal}
				handleRemoveWhitelistingIP={removeWhitelistIpRequestHandler}
			/>
			<VerifyWhitelistingIPModal
				openModal={openVerifyModal}
				closeModal={closeVerifyModal}
				handleVerifyWhitelistingIP={verifyWhitelistIpRequestHandler}
			/>
		</>
	);
};
