import { isAuth, isGuest } from 'routes/authMiddlewares';
import Home from 'pages/Dashboard/Home';
import AdminManagementPage from 'pages/Dashboard/AdminManagementPage';
import DisputePage from 'pages/Dashboard/Dispute';
import UserManagementPage from 'pages/Dashboard/UserManagementPage';
import Settings from 'pages/Dashboard/Settings';
import Login from 'pages/Auth/Login';
import SelectUserPage from 'pages/Dashboard/SelectUserPage';
import ConvetSettings from 'pages/Dashboard/ConvertSettings';
import CreateAdminPage from 'pages/Dashboard/CreateAdminPage';
import EditAdminPage from 'pages/Dashboard/EditAdminPage';
import TwoFAPage from 'pages/Dashboard/TwoFAPage';
import NotificationPage from 'pages/Dashboard/NotificationPage';
import FeeManagementPage from 'pages/Dashboard/FeeManagementPage';
import FeeManagementFiatPage from 'pages/Dashboard/FeeManagementFiatPage';
import WithdrawalsPage from 'pages/Dashboard/Withdrawals';
import OrdersPage from 'pages/Dashboard/Orders';
import TradesPage from 'pages/Dashboard/Trades';
import TradingPairsPage from 'pages/Dashboard/TradingPairsPage';
import { WalletManagementPage } from 'pages/Dashboard/WalletManagementPage';
import MarketMakerRobotPage from 'pages/Dashboard/MarketMakerRobotPage';
import LimitationsPage from 'pages/Dashboard/Limitations';
import TransactionsPage from 'pages/Dashboard/Transactions';
import CurrenciesPage from 'pages/Dashboard/Currencies';
import DisputeTubPage from 'pages/Dashboard/DisputeTubPage';
import TokenManagementPage from 'pages/Dashboard/TokenManagementPage';
import BankAccountPage from 'pages/Dashboard/BankAccountPage';
import ExternalMakerRobotPage from 'pages/Dashboard/ExternalMakerRobotPage';
// import Translations from 'pages/Dashboard/Translations';
import ReferralPage from 'pages/Dashboard/Referral';
import SelectReferralPage from 'pages/Dashboard/SelectReferralPage';
import StakingPage from 'pages/Dashboard/Staking';
import MarketBotPage from 'pages/Dashboard/MarketBot';
import { IRouteItem } from './types';

// ==========================================:
const navList = {
	home: {
		path: '/',
		component: isAuth(Home),
	},
	adminManagementPage: {
		path: '/admin-management',
		component: isAuth(AdminManagementPage),
	},
	disputePage: {
		path: '/dispute',
		component: isAuth(DisputePage),
	},
	createAdmin: {
		path: '/admin-management/new-admin',
		component: isAuth(CreateAdminPage),
	},
	editAdmin: {
		path: '/admin-management/edit/:id',
		component: isAuth(EditAdminPage),
	},
	userManagementPage: {
		path: '/user-management',
		component: isAuth(UserManagementPage),
	},
	selectUser: {
		path: '/user-management/:id',
		component: isAuth(SelectUserPage),
	},
	withdrawals: {
		path: '/pending-withdrawals',
		component: isAuth(WithdrawalsPage),
	},
	orders: {
		path: '/orders',
		component: isAuth(OrdersPage),
	},
	trades: {
		path: '/trades',
		component: isAuth(TradesPage),
	},
	twoFAPage: {
		path: '/2FA',
		component: isAuth(TwoFAPage),
	},
	tradingPairsPage: {
		path: '/trading-pairs',
		component: isAuth(TradingPairsPage),
	},
	notificationPage: {
		path: '/notification',
		component: isAuth(NotificationPage),
	},
	feeManagmentPage: {
		path: '/fee-management',
		component: isAuth(FeeManagementPage),
	},
	feeManagmentFiatPage: {
		path: '/fee-management-fiat',
		component: isAuth(FeeManagementFiatPage),
	},
	walletManagementPage: {
		path: '/wallet-management',
		component: isAuth(WalletManagementPage),
	},
	settings: {
		path: '/settings',
		component: isAuth(Settings),
	},
	convertSettings: {
		path: '/convert-settings',
		component: isAuth(ConvetSettings),
	},
	login: {
		path: '/login',
		component: isGuest(Login),
	},
	internal: {
		path: '/internal',
		component: isAuth(MarketMakerRobotPage),
	},
	external: {
		path: '/external',
		component: isAuth(ExternalMakerRobotPage),
	},
	limitations: {
		path: '/limitations',
		component: isAuth(LimitationsPage),
	},
	transactions: {
		path: '/transactions',
		component: isAuth(TransactionsPage),
	},
	currencies: {
		path: '/currencies',
		component: isAuth(CurrenciesPage),
	},
	disputeTub: {
		path: '/dispute-info/:disputeId',
		component: isAuth(DisputeTubPage),
	},
	tokenManagement: {
		path: '/token-management',
		component: isAuth(TokenManagementPage),
	},
	bankAccount: {
		path: '/bank-account',
		component: isAuth(BankAccountPage),
	},
	// translations: {
	// 	path: '/translations',
	// 	component: isAuth(Translations),
	// },
	referral: {
		path: '/referral',
		component: isAuth(ReferralPage),
	},
	referralSelect: {
		path: '/referral/:id',
		component: isAuth(SelectReferralPage),
	},
	staking: {
		path: '/staking',
		component: isAuth(StakingPage),
	},
	marketBot: {
		path: '/market-bot',
		component: isAuth(MarketBotPage),
	},
};
// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList };
