import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import { getTokenTime } from 'redux/reducers/auth/selectors';
import { notificationContainer } from 'utils/notificationContainer';
import { authInitState } from 'redux/reducers/auth/reducer';
import Header from 'layouts-elements/Header';
import Sidebar from 'layouts-elements/Sidebar';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const tokenExpirationTime = useSelector(getTokenTime);
	const dispatch = useDispatch();

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;
		if (tokenExpirationTime !== null) {
			const timeStamp = new Date();
			const date = new Date(tokenExpirationTime);
			const { length } = String(Date.parse(String(date)));
			const currentTime = String(Date.parse(String(timeStamp))).slice(0, length);
			const secondsToEnd = tokenExpirationTime - Number(currentTime);
			timer = setTimeout(() => {
				notificationContainer('Session has ended', 'info');
				dispatch(authInitState());
			}, secondsToEnd * 1000);
		}
		return () => clearTimeout(timer);
	}, [dispatch, tokenExpirationTime]);

	const mobileMenuStatus = useSelector(getMobileMenuStatus);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				<div className="content">
					<Header />
					<main className="main">
						<section className={`main-section ${mobileMenuStatus ? 'minimized' : ''}`}>
							<SocketWithPrivateTopicConnectWrapper topicName="admin_notifications">
								<SocketWithPrivateTopicConnectWrapper topicName="dispute_evidence_updated">
									<Sidebar />
								</SocketWithPrivateTopicConnectWrapper>
							</SocketWithPrivateTopicConnectWrapper>

							<div className="main-content">{children}</div>
						</section>
					</main>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
