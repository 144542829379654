/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ITranslatesStore,
	ITranslates,
	IGetTranslateParams,
	IUpdateTranslatePayload,
} from './types';
// ==========================================:
export const initialState: ITranslatesStore = {
	translates: null,
	loading: false,
	updateLoading: false,
};

// ==========================================:
const translations = createSlice({
	name: '@@Translates',
	initialState,
	reducers: {
		getTranslatesRequest: (state, action: PayloadAction<IGetTranslateParams>) => {
			const translatesState = state;
			translatesState.loading = true;
		},
		getTranslatesSuccess: (state, { payload }: PayloadAction<ITranslates[]>) => {
			const translatesState = state;
			translatesState.loading = false;
			translatesState.translates = payload;
		},
		getTranslatesError: (state) => {
			const translatesState = state;
			translatesState.loading = false;
		},
		updateTranslatesRequest: (state, action: PayloadAction<IUpdateTranslatePayload>) => {
			const translatesState = state;
			translatesState.updateLoading = true;
		},
		updateTranslatesSuccess: (
			state,
			action: PayloadAction<{ path: string; response: ITranslates }>,
		) => {
			const translatesState = state;
			translatesState.updateLoading = false;
		},
		updateTranslatesError: (state) => {
			const translatesState = state;
			translatesState.updateLoading = false;
		},
	},
});

export default translations.reducer;
export const {
	getTranslatesRequest,
	getTranslatesSuccess,
	updateTranslatesRequest,
	updateTranslatesSuccess,
	getTranslatesError,
	updateTranslatesError,
} = translations.actions;
