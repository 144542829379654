import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import SearchBar from 'components/SearchBar';
import { IMarketMakerPageRequestPayload } from 'redux/reducers/orders/types';
import { getFilterParams } from 'redux/reducers/orders/selectors';
import { getOrdersSpotRequest, setFilterParams } from 'redux/reducers/orders/reducer';
import {
	USER_SEARCH_ARRAY,
	USER_SEARCH_TEXT_ARRAY,
	USER_STATUS_ARRAY,
	USER_STATUS_TEXT_ARRAY,
	USER_LEVEL_ARRAY,
} from '../../constants';

import { TradeFilter } from '../Filter';
/* eslint-disable react-hooks/exhaustive-deps */

export const SpotFilter = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const filterParams = useSelector(getFilterParams);

	const handleDataSelect = (start_date: string | null, end_date: string | null) => {
		dispatch(setFilterParams({ start_date, end_date }));
	};
	const location = useLocation();

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [searchField, setSearchField] = useState(field ? String(field) : 'user_id');

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	/* eslint-disable */
	const handleSearch = (txt: string) => {
		const params = {
			current_page: 1,
			search_value: txt.length >= 1 ? txt : undefined,
			search_field: txt.length >= 1 ? searchField  : undefined,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};

		dispatch(getOrdersSpotRequest(params));
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${1}&text=${String(txt)}&field=${searchField}`
					: `?page=${1}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField, startDate, endDate]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'status') {
			setDropdownArray({
				dropdownArray: USER_STATUS_ARRAY,
				dropdownTextArray: USER_STATUS_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		if (txt === 'level') {
			setDropdownArray({
				dropdownArray: USER_LEVEL_ARRAY,
				dropdownTextArray: USER_LEVEL_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	const handleExport = () => {
		const params: IMarketMakerPageRequestPayload = {
			current_page: page || 1,
			export: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
			search_value: text && text.length >= 1 ? String(text) : undefined,
			search_field: text && text.length >= 1 ? searchField  : undefined,
		};
		dispatch(getOrdersSpotRequest(params));
	};
	return (
		<div className="count-label count-label--filter-type">
			<SearchBar
				setDebouncedTerm={setDebouncedTerm}
				debouncedTerm={debouncedTerm}
				searchFieldArray={USER_SEARCH_ARRAY}
				searchField={searchField}
				setSearchField={searchFieldHandler}
				handleClearSearch={handleClearSearch}
				searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
				typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
				dropdownArray={dropdownArray.dropdownArray}
				dropdownTextArray={dropdownArray.dropdownTextArray}
			/>
			<TradeFilter
				exportTitle="Export spot orders"
				onDateSelect={handleDataSelect}
				onExport={handleExport}
			/>
		</div>
	);
};
