/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { cryptoTransactionsDepositStatus } from 'redux/reducers/transactions/constants';
import { notificationContainer } from 'utils/notificationContainer';
import { noExponent } from 'utils/numberFormat';
import { trimTransactionHash } from 'utils/trimComment';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { ICryptoItemProps } from './types';

export const CryptoItem: FC<ICryptoItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);
	const { checkReadable } = usePermission();

	const handleAddressCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
	};
	const handleTransactionUrlCopy = () => {
		notificationContainer('Transactions url copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			{checkReadable(permissions.MANAGE_USERS) ? (
				<Link className="td" to={`/user-management/${String(data.user_id)}`}>
					<p className="td-hidden-name">User ID</p>
					<p>{data.user_id}</p>
				</Link>
			) : (
				<div className="td">
					<p className="td-hidden-name">User ID</p>
					<p>{data.user_id}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Asset</p>
				<p>{data.asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{data.network.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Network fee</p>
				<p>{noExponent(Number(data.fee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{Number(data.amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount USD</p>
				<p>{Number(data.amount_usd)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Destination</p>
				<p className="copy-button">
					<span className="copy-button__text">{trimTransactionHash(data?.address)}</span>
					<CopyToClipboard text={data?.address} onCopy={handleAddressCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Comment manual</p>
				<p className="text-ellipsis">{data.manual_deposit_comment}</p>
			</div>
			<div className="td td--right td--status">
				<p className="td-hidden-name">Status</p>
				<div>
					<span
						className={`status ${
							cryptoTransactionsDepositStatus?.[data?.status]?.statusClass ?? ''
						}`}
					>
						{cryptoTransactionsDepositStatus?.[data?.status]?.statusText}
					</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Destination</p>
				<p className="copy-button">
					<span className="copy-button__text">{trimTransactionHash(data?.hash)}</span>
					<CopyToClipboard text={data?.tx_url} onCopy={handleTransactionUrlCopy}>
						<button type="button" className="copy-button__item">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M3.33337 10.0007H2.66671C2.31309 10.0007 1.97395 9.86018 1.7239 9.61013C1.47385 9.36008 1.33337 9.02094 1.33337 8.66732V2.66732C1.33337 2.3137 1.47385 1.97456 1.7239 1.72451C1.97395 1.47446 2.31309 1.33398 2.66671 1.33398H8.66671C9.02033 1.33398 9.35947 1.47446 9.60952 1.72451C9.85956 1.97456 10 2.3137 10 2.66732V3.33398"
									stroke="#777E91"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					</CopyToClipboard>
				</p>
			</div>
		</div>
	);
};
