import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	getGeneralRequest,
	getGeneralSuccess,
	changeGeneralRequest,
	getP2PRequest,
	getP2PSuccess,
	setP2PCurrencyStatus,
} from './reducer';
import { IOrdersResponsePayload, IGeneralCurrencyPayload } from './types';
import { changeLimitationsP2PSuccess } from '../limitations/reducer';
import { IChangeLimitationsP2PLimitsResponse } from '../limitations/types';
// =============================================================:
// =============================================================:

function* generalRequestWorker() {
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.currencies.getGeneral);
		yield put(getGeneralSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* generalChangeRequestWorker({ payload }: PayloadAction<IGeneralCurrencyPayload>) {
	const { onError, apiParams, onSuccess } = payload;

	try {
		yield put(showLoading());
		const response: IChangeLimitationsP2PLimitsResponse = yield call(
			api.currencies.changeGeneral,
			apiParams,
		);
		notificationContainer('Successfully updated!', 'success');
		yield put(changeLimitationsP2PSuccess(response));
		const responseSuccess: IOrdersResponsePayload = yield call(api.currencies.getGeneral);
		yield put(getGeneralSuccess(responseSuccess));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		onError?.();
	} finally {
		yield put(hideLoading());
	}
}

function* p2pRequestWorker() {
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.currencies.getP2P);
		yield put(getP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* p2pCurrencyStatusWorker(action: PayloadAction<{ id: number; active: number }>) {
	const { payload } = action;
	try {
		yield call(api.currencies.setCurrencyStatus, payload);
		const response: IOrdersResponsePayload = yield call(api.currencies.getP2P);
		yield put(getP2PSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* currenciesSaga() {
	yield takeEvery(getGeneralRequest.type, generalRequestWorker);
	yield takeEvery(getP2PRequest.type, p2pRequestWorker);
	yield takeEvery(changeGeneralRequest.type, generalChangeRequestWorker);
	yield takeLatest(setP2PCurrencyStatus.type, p2pCurrencyStatusWorker);
}
