import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	tokenManagementEditRequest,
	tokenManagementHistoryRequest,
	tokenManagementRequest,
} from 'redux/reducers/tokenManagement/reducer';
import Item from './TokenManagement/Item';
import {
	getManagementStore,
	getManagementStoreLoading,
	getManagementStoreUpdate,
} from '../../redux/reducers/tokenManagement/selectors';
import {
	ITokenManagementEditParams,
	ITokenManagementResponse,
} from '../../redux/reducers/tokenManagement/types';
import Loader from '../../ui/Loader';
import HistoryTab from './HistoryTab';

const TokenManagement: FC = () => {
	const dispatch = useDispatch();
	const data = useSelector(getManagementStore);
	const loading = useSelector(getManagementStoreLoading);
	const [activeTub, setActiveTub] = useState('GEL');

	useEffect(() => {
		if (activeTub === 'USD' || activeTub === 'GEL' || activeTub === 'EUR') {
			dispatch(tokenManagementRequest({ code: `${activeTub.toLowerCase()}b` }));
		}
		if (activeTub === 'History') {
			dispatch(tokenManagementHistoryRequest({ current_page: 1, per_page: 20 }));
		}
	}, [dispatch, activeTub]);

	const editTokenManagement = (editData: ITokenManagementEditParams) => {
		dispatch(tokenManagementEditRequest(editData));
	};

	return (
		<>
			<div className="title-block title-block--admin-managment">
				<p className="title">Token Management</p>
			</div>
			<div className="content-block">
				<div className="content-block__inside content-block__inside--type2">
					<ul className="tabs-list tabs-list--type2">
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'GEL' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('GEL');
								}}
								className="tabs-list__link"
							>
								GEL
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'EUR' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('EUR');
								}}
								className="tabs-list__link"
							>
								EUR
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'USD' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('USD');
								}}
								className="tabs-list__link"
							>
								USD
							</span>
						</li>
						<li
							style={{ cursor: 'pointer' }}
							className={`tabs-list__item ${activeTub === 'History' ? 'active' : ''}`}
						>
							<span
								onClick={() => {
									setActiveTub('History');
								}}
								className="tabs-list__link"
							>
								History
							</span>
						</li>
					</ul>
					{data && (activeTub === 'USD' || activeTub === 'GEL' || activeTub === 'EUR')
						? data?.map((e: ITokenManagementResponse) => {
								return (
									<Item
										key={`${e.asset_id}${e.network_id}`}
										item={e}
										editTokenManagement={editTokenManagement}
										currency={String(activeTub?.toLowerCase())}
									/>
								);
						  })
						: null}
					{activeTub === 'History' && <HistoryTab />}
					{loading ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : null}
				</div>
			</div>
		</>
	);
};

export default TokenManagement;
