import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import TokenManagement from 'components/TokenManagement';
// ================================================:
const TokenManagementPage: FC = () => {
	return (
		<Dashboard title="Token Management">
			<TokenManagement />
		</Dashboard>
	);
};
export default TokenManagementPage;
