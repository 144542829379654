import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	tokenManagementMintRequest,
	tokenManagementBurnRequest,
	tokenManagementCheckRequest,
} from 'redux/reducers/tokenManagement/reducer';
import { CheckPopup } from './CheckPopup';
import { PropsItem } from './types';

const Item: FC<PropsItem> = ({ item, editTokenManagement, currency }) => {
	const dispatch = useDispatch();
	const { asset_id: assetId, network_id: networkId } = item;
	const customBooleon = (value: boolean | number) => {
		if (value === true) {
			return true;
		}
		if (value === false) {
			return false;
		}
		if (value === 1) {
			return true;
		}
		if (value === 0) {
			return false;
		}
		return false;
	};
	const customNumber = (value: boolean | number) => {
		return value ? 1 : 0;
	};
	const [issued, setIssued] = useState(item.issued);
	const [burned, setBurned] = useState(item.burned);
	const [percent, setPercent] = useState(item.fee_percent);
	const [checkInfo, setCheckInfo] = useState<{
		mint: number;
		burn: number;
	} | null>(null);
	const EthereumText = () => {
		switch (item.network_id) {
			case 'eth':
				return 'Ethereum';
			case 'bsc':
				return 'BSC';
			case 'trx':
				return 'TRON';
			default:
				return null;
		}
	};

	const handleMint = () => {
		dispatch(tokenManagementMintRequest({ asset_id: assetId, network: networkId }));
	};

	const handleBurn = () => {
		dispatch(tokenManagementBurnRequest({ asset_id: assetId, network: networkId }));
	};

	const handleCheck = () => {
		dispatch(
			tokenManagementCheckRequest({
				params: { asset_id: assetId, network: networkId },
				onSuccess: (result) => {
					setCheckInfo(result.data);
				},
			}),
		);
	};

	return (
		<div className="tokens-management-box">
			<a
				href={`${item?.contract_url}`}
				className="nav-link d-inline"
				target="_blank"
				rel="noopener noreferrer"
			>
				<p className="block-title block-title--big">Blockchain {EthereumText()} (Token info)</p>
			</a>
			<div className="tokens-management-list">
				<div className="tokens-management">
					<p className="tokens-management__title">Number of tokens issued</p>
					<p className="tokens-management__value">{parseFloat(String(item.issued))}</p>
					<div className="input">
						<p className="input__name input__name--type2">Need to issue</p>
						<div className="input-wrapper">
							<input
								onChange={(e) => {
									setIssued(e.target.value);
								}}
								className="input-item input-item--fw-400 "
								type="number"
								disabled={percent === 'n/a'}
								placeholder=""
								value={parseFloat(String(issued))}
							/>
						</div>
					</div>
					<div className="tokens-management__btn">
						<button
							disabled={percent === 'n/a'}
							onClick={() => {
								const data = {
									network: item.network_id,
									asset_id: item.asset_id,
									value: issued,
									url: 'issue',
									key: 'amount',
								};
								editTokenManagement(data);
							}}
							className="button button--full-width button--type3"
							type="button"
						>
							Issue tokens
						</button>
						<button
							className="button button--full-width button--red-type"
							type="button"
							onClick={handleMint}
						>
							Check & Mint
						</button>
					</div>
				</div>
				<div className="tokens-management">
					<p className="tokens-management__title">Number of tokens burned</p>
					<p className="tokens-management__value">{parseFloat(String(item.burned))}</p>
					<div className="input">
						<p className="input__name input__name--type2">Burn of tokens</p>
						<div className="input-wrapper">
							<input
								onChange={(e) => {
									setBurned(e.target.value);
								}}
								className="input-item input-item--fw-400 "
								type="number"
								disabled={percent === 'n/a'}
								placeholder=""
								value={parseFloat(String(burned))}
							/>
						</div>
					</div>
					<div className="tokens-management__btn">
						<button
							disabled={percent === 'n/a'}
							onClick={() => {
								const data = {
									network: item.network_id,
									asset_id: item.asset_id,
									value: burned,
									url: 'burn',
									key: 'amount',
								};
								editTokenManagement(data);
							}}
							className="button button--full-width button--type3"
							type="button"
						>
							Burn tokens
						</button>
						<button
							className="button button--full-width button--red-type"
							type="button"
							onClick={handleBurn}
						>
							Check & Burn
						</button>
					</div>
				</div>
				<div className="tokens-management">
					<p className="tokens-management__title">Transaction Fee</p>
					<p className="tokens-management__value">{item.fee_percent}%</p>
					<div className="input">
						<p className="input__name input__name--type2">Need to issue</p>
						<div className="input-wrapper">
							<input
								onChange={(e) => {
									setPercent(e.target.value);
								}}
								disabled={percent === 'n/a'}
								className="input-item input-item--fw-400 "
								type="number"
								placeholder=""
								value={percent === 'n/a' ? '' : parseFloat(String(percent))}
							/>
						</div>
					</div>
					<div className="tokens-management__btn">
						<button
							disabled={percent === 'n/a'}
							onClick={() => {
								const data = {
									network: item.network_id,
									asset_id: item.asset_id,
									value: percent,
									url: 'fee',
									key: 'fee',
								};
								editTokenManagement(data);
							}}
							className="button button--full-width button--type3"
							type="button"
						>
							Edit fee
						</button>
						<button
							className="button button--full-width button--type5"
							type="button"
							onClick={handleCheck}
						>
							Check
						</button>
					</div>
				</div>
				<div className="tokens-management">
					<p className="tokens-management__title">Put contract on pause</p>

					<div className="tokens-management__btn">
						<div className="switch switch--type2 switch--auto-height ">
							<label className="switch__label">
								<input
									onChange={() => {
										const data = {
											network: item.network_id,
											asset_id: item.asset_id,
											value: customNumber(!item?.on_pause),
											url: 'pause',
											key: 'paused',
										};
										editTokenManagement(data);
									}}
									checked={customBooleon(item?.on_pause)}
									disabled={percent === 'n/a'}
									type="checkbox"
									className="hidden"
								/>
								<div
									style={percent === 'n/a' ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
									className="switch__toggler"
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
			<CheckPopup
				openModal={!!checkInfo}
				closeModal={() => setCheckInfo(null)}
				mint={checkInfo?.mint}
				burn={checkInfo?.burn}
				currency={currency}
			/>
		</div>
	);
};

export default Item;
