import { useState, FC, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { updateMarketBotRequest } from 'redux/reducers/marketBot/reducer';
import { notificationContainer } from 'utils/notificationContainer';
import { IMarketBotTableRow } from './types';

const MarketBotTableRow: FC<IMarketBotTableRow> = ({ item }) => {
	const dispatch = useDispatch();
	const {
		id,
		asset_pair: assetPair,
		market_range: marketRange,
		max_ask_volume: maxAskVolume,
		max_bid_volume: maxBidVolume,
		initial_spread: initialSpread,
		frequency,
		is_active: isActive,
		current_ask_volume: currentAskVolume,
		current_bid_volume: currentBidVolume,
		last_scan_time: lastScanTime,
	} = item;

	const getTime = () => {
		if (!lastScanTime) {
			return null;
		}
		const date = new Date(lastScanTime * 1000);
		const offset = (-date.getTimezoneOffset() * 60) / 1000;
		const dateOffset = new Date((+lastScanTime + offset) * 1000);
		return (
			<>
				{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> &bull; </span>
				{dateOffset.toLocaleTimeString()}
			</>
		);
	};

	const [edit, setEdit] = useState(false);
	const formInit = {
		market_range: marketRange,
		max_ask_volume: maxAskVolume,
		max_bid_volume: maxBidVolume,
		initial_spread: initialSpread,
		frequency,
		is_active: isActive,
	};
	const [form, setForm] = useState(formInit);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [toggleActiveLoading, setToggleActiveLoading] = useState(false);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value, name },
		} = e;
		setForm((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = () => {
		setIsSubmitting(true);
		dispatch(
			updateMarketBotRequest({
				apiParams: {
					id,
					market_range: form.market_range ? Number(form.market_range) : undefined,
					max_ask_volume: form.market_range ? Number(form.max_ask_volume) : undefined,
					max_bid_volume: form.market_range ? Number(form.max_bid_volume) : undefined,
					initial_spread: form.market_range ? Number(form.initial_spread) : undefined,
					frequency: form.market_range ? Number(form.frequency) : undefined,
				},
				onSuccess: () => {
					setIsSubmitting(false);
					setEdit(false);
					notificationContainer('Market bot edited', 'success');
				},
				onError: () => {
					setIsSubmitting(false);
				},
			}),
		);
	};

	const toggleActive = () => {
		setToggleActiveLoading(true);
		dispatch(
			updateMarketBotRequest({
				apiParams: {
					id,
					is_active: isActive ? 0 : 1,
				},
				onSuccess: () => {
					setToggleActiveLoading(false);
				},
				onError: () => {
					setToggleActiveLoading(false);
					setForm((prev) => ({
						...prev,
						is_active: isActive,
					}));
				},
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Pair</p>
				<p>{assetPair?.code?.replace('_', '/').toUpperCase()}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">Market range %</p>
				{edit ? (
					<div className="table-input table-input--start ">
						<input
							type="number"
							name="market_range"
							placeholder="0"
							value={form.market_range || ''}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<p>{marketRange}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Ask max</p>
				{edit ? (
					<div className="table-input table-input--start ">
						<input
							type="number"
							name="max_ask_volume"
							placeholder="0"
							value={form.max_ask_volume || ''}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<p>{maxAskVolume}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Bid max</p>
				{edit ? (
					<div className="table-input table-input--start ">
						<input
							type="number"
							name="max_bid_volume"
							placeholder="0"
							value={form.max_bid_volume || ''}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<p>{maxBidVolume}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Spread</p>
				{edit ? (
					<div className="table-input table-input--start ">
						<input
							type="number"
							name="initial_spread"
							placeholder="0"
							value={form.initial_spread || ''}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<p>{initialSpread}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Frequency</p>
				{edit ? (
					<div className="table-input table-input--start ">
						<input
							type="number"
							name="frequency"
							placeholder="0"
							value={form.frequency || ''}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<p>{frequency}</p>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Curreny ask volume</p>
				<p>{currentAskVolume}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Curreny bid volume</p>
				<p>{currentBidVolume}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>{getTime()}</p>
			</div>
			<div className="td ">
				<p className="td-hidden-name">On/Off</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							checked={form.is_active === 1}
							onChange={() => {
								setForm((prev) => ({
									...prev,
									is_active: prev.is_active === 1 ? 0 : 1,
								}));
								toggleActive();
							}}
							className="hidden"
						/>
						<div
							className="switch__toggler"
							style={toggleActiveLoading ? { cursor: 'not-allowed' } : {}}
						/>
					</label>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{edit ? (
						<>
							<button
								type="button"
								disabled={isSubmitting}
								onClick={() => {
									setEdit(false);
									setForm(formInit);
								}}
							>
								<span className="edit-icon icon-cancel-icon" />
							</button>
							<button type="button" disabled={isSubmitting}>
								<span className="edit-icon icon-succes-icon" onClick={handleSubmit} />
							</button>
						</>
					) : (
						<button type="button" onClick={() => setEdit(true)}>
							<span className="edit-icon edit-icon--action icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default MarketBotTableRow;
